<template>
  <li
    v-if="$CanViewMenu(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-if="t(item.title) != 'Marketing Dashboard'" v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" style="color:white" />
      <span class="menu-title text-truncate" style="color:white">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag  }} 

      </b-badge>

    </b-link>




    

    <b-link :href="companyUrl" target= "_blank" v-else class=" d-flex align-items-center">

      <feather-icon :icon="item.icon || 'CircleIcon'" style="color:white" />
      <span  class="menu-title text-truncate" style="color:white">{{ t(item.title) }}</span>
      <b-badge   v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag  }} 

      </b-badge>
    </b-link>

    
<!-- 
    <b-link v-else v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" style="color:white" />
      <span class="menu-title text-truncate" style="color:white">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag  }} 

      </b-badge>

    </b-link> -->



  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { BLink, BBadge } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';

export default {



  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },



  computed: {
    companyUrl() {
      const userData = JSON.parse(localStorage.getItem('userData'));
      return userData ? userData.companyUrl : '';
    }
  },
  watch: {
    $route(to, from) {
      // Refresh the computed property on route change
      
      const userData = JSON.parse(localStorage.getItem('userData'));
    }
  }

};
</script>
